<template>
  <div class="rt2">
    <!-- 左侧 -->
    <div class="r-l">
      <!-- 标题 -->
      <div class="title">
        <i class="iconfont icon-tubiaozuixin01"></i>
        热门推荐
      </div>

      <!-- 循环项 -->
      <div
        class="l-item"
        v-for="(item, index) in this.a1"
        :key="index"
        @click="gopay"
      >
        <!-- 左侧图片 -->
        <div class="item-img">
          <img :src="item.img" alt="" />
        </div>
        <!-- 右侧信息 -->
        <div class="item-info">
          <!-- 标题 -->
          <div class="item-title">
            {{ item.title }}
          </div>
          <!-- 简介 -->
          <div class="item-jj">
            {{ item.jj }}
          </div>
          <!-- 信息层 -->
          <div class="item-time">
            <!-- 作者 -->
            <span>{{ item.author }}</span>
            <div class="time-r">
              <!-- 点赞 -->
              <span><i class="iconfont icon-dianzan"></i>{{ item.sub }}</span>
              <!-- 发布时间 -->
              <span style="color: #c60023; margin-left: 8px"> 付费阅读：2元/篇 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="r-r">
      <!-- 标题 -->
      <div class="title">
        <!-- <i class="iconfont icon-tubiaozuixin01"></i> -->
        DEPTH /深度聚焦
      </div>

      <!-- banner图片 -->
      <div class="banner">
        <img src="/imgs/c2.jpg" alt="" />
        <span>北京连下暴雨，你家的防水工程还顶得住吗？</span>
      </div>

      <!-- 循环项 -->
      <div
        class="r-item"
        v-for="(item, index) in this.a2"
        :key="index"
        @click="gopay"
      >
        <div class="item-title">{{ item.title }}</div>
        <img :src="item.img" alt="" />
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img
          style="display: flex; justify-content: center; margin: 0 auto"
          src="/imgs/pay2.png"
        />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  data() {
    return {
      data: [],
      a1: [],
      a2: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  components: {
    Modal,
  },
  mounted() {
    this.init();
    document.title = "北京星圣科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.data = data;
        this.a1 = data.slice(0, 4);
        this.a2 = data.slice(5, 10);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 详情
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    goLogin() {
      alert("资讯每篇2元，点击确定购买");
      this.$router.push("/login");
    },
    // 收费逻辑
    gopay() {
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.rt2 {
  width: 100%;
  // height: 1200px;
  // @include flex();
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin: 60px auto;
  //左侧
  .r-l {
    width: 60%;
    height: 100%;
    // background-color: #c60023;
    background-color: #fff;
    // 标题
    .title {
      width: 100%;
      height: 40px;
      //   background-color: pink;
      display: flex;
      align-items: center;
      border-bottom: 2px dashed #ccc;
      i {
        font-size: 35px;
        // color: #c60023;
        color: $colorZ;
        margin-right: 15px;
      }
      color: #333;
      font-weight: 400;
      font-size: 18px;
    }

    // 循环项
    .l-item {
      width: 100%;
      height: 220px;
      //   background-color: pink;
      // @include flex();
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
      // 左侧图片
      .item-img {
        width: 48%;
        // height: 250px;
        height: 100%;
        // background-color: aqua;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 右侧信息
      .item-info {
        width: 52%;
        height: 100%;
        padding: 15px;
        box-sizing: border-box;
        //   标题
        .item-title {
          font-size: 16px;
          color: $colorZ;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        // 简介
        .item-jj {
          width: 100%;
          font-size: 12px;
          color: #888;
          line-height: 30px;
          text-indent: 30px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          margin: 10px auto;
        }

        // 信息层
        .item-time {
          width: 100%;
          height: 50px;
          // @include flex();
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 12px;
            color: #888;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              color: $colorZ;
            }
            i {
              margin: 5px;
            }
          }
        }
      }
    }
  }

  //   右侧
  .r-r {
    width: 40%;
    height: 100%;
    background-color: #fff;
    // padding: 20px;
    margin-left: 30px;
    // 标题
    .title {
      width: 100%;
      height: 40px;
      //   background-color: pink;
      display: flex;
      align-items: center;
      border-bottom: 2px dashed #ccc;
      color: #333;
      font-weight: 400;
      font-size: 18px;
    }

    // banner 图片区块
    .banner {
      width: 100%;
      height: 200px;
      background-color: red;
      margin: 20px 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.19);
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 14px;
        line-height: 50px;
        color: #fff;
      }
    }

    // 循环项
    .r-item {
      width: 100%;
      height: 120px;
      margin: 15px 0;
      transition: all 0.2s;
      //  @include flex();
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: #eee;
        .item-title {
          color: $colorZ;
        }
      }

      .item-title {
        transition: all 0.2s;
        width: 50%;
        padding-left: 20px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 200px;
        height: 100%;
      }
    }
  }
}
</style>
