<template>
  <div class="rt1">
    <!-- 左侧 -->
    <div class="r-l">
      <!-- bannert -->
      <div class="banner">
        <img src="/imgs/c1.jpg" alt="" />
      </div>
      <!-- 两个小图片区块 -->
      <div class="l-l">
        <!-- 循环项 -->
        <div
          class="l-item"
          v-for="(item, index) in this.a1"
          :key="index"
          @click="gopay()"
        >
          <img :src="item.img" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="r-r">
      <!-- 循环项 -->
      <div
        class="r-item"
        v-for="(item, index) in this.a2"
        :key="index"
        @click="gopay()"
      >
        <!-- 标题 -->
        <div class="item-title">{{ item.title }}</div>
        <!-- 简介 -->
        <div class="item-jj">
          {{ item.jj }}
        </div>
      </div>
    </div>
    <!-- 弹框部分 -->
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img
          style="display: flex; justify-content: center; margin: 0 auto"
          src="/imgs/pay2.png"
        />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      data: [],
      a1: [],
      a2: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "北京星圣科技有限公司";
  },
  methods: {
    goLogin() {
      alert("资讯每篇2元，点击确定购买");
      this.$router.push("/login");
    },
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.data = data;
        this.a1 = data.slice(0, 2);
        this.a2 = data.slice(2, 4);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 详情
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费3元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.rt1 {
  width: 100%;
  height: 300px;
  //   background: pink;
  //   margin: 30px auto;
  margin-top: 120px;
  @include flex();
  //左侧
  .r-l {
    height: 100%;
    width: 60%;
    background: pink;
    @include flex();
    // banner
    .banner {
      width: 70%;
      height: 100%;
      background: #ff6600;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // 右侧两张图片
    .l-l {
      width: 40%;
      height: 100%;
      // background: #ec1054;
      // 循环项
      .l-item {
        width: 100%;
        height: 150px;
        // margin: 15px 0;
        // margin-bottom: 20px;
        // background-color: #ff6600;
        position: relative;
        overflow: hidden;
        &:hover {
          img {
            transform: scale(1.1);
          }
          p {
            height: 50px;
          }
        }
        img {
          //   position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          transition: all 0.2s;
        }
        // p简介
        p {
          width: 100%;
          //   height: 50px;
          height: 0;
          background-color: rgba(0, 0, 0, 0.7);
          position: absolute;
          left: 0;
          bottom: 0;
          line-height: 50px;
          font-size: 12px;
          color: #fff;
          transition: all 0.2s;
        }
      }
    }
  }
  // 右侧
  .r-r {
    width: 40%;
    height: 100%;
    // background: #c60023;
    margin-left: 30px;

    // 循环项
    .r-item {
      width: 100%;
      height: 149px;
      //   background-color: pink;
      padding: 15px;
      box-sizing: border-box;
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: #eee;
      }
      &:nth-child(2) {
        .item-title {
          text-align: right;
        }
      }
      //   标题
      .item-title {
        font-size: 16px;
        color: $colorZ;
        margin-bottom: 20px;
      }
      //   简介
      .item-jj {
        width: 100%;
        font-size: 14px;
        color: #888;
        line-height: 25px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
}
</style>
