<template>
  <div class="nav-header">
    <div class="container">
      <!-- 左侧 -->
      <div class="n-l">
        <!-- logo -->
        <div class="n-logo">
          <!-- <img src="/imgs/logo.png" alt="" /> -->
          北京点悦互动软件科技有限公司
        </div>

        <!-- 列表 -->
        <div class="n-list">
          <a href="/">网站首页</a>
          <a href="/#/ac1">行业资讯</a>
          <!-- <a href="/#/ac2">装修资讯</a> -->
          <!-- <a href="/#/ac3">房屋资讯</a> -->
          <a href="/#/xy">用户协议</a>
          <a href="/#/info">关于我们</a>
          <!-- <a href="/#/info">关于我们</a> -->
        </div>
      </div>

      <!-- 登录/注册 -->
      <div class="n-login">
        <div class="n-login2" @click="goLogin()" v-show="this.LoggedShow">登录/注册</div>

        <!-- 用户名 -->
        <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    goLogin() {
      this.$router.push("/login");
    },
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 15px 10px -15px #ccc;
  z-index: 10;
  .container {
    height: 100%;
    @include flex();
    // 左侧
    .n-l {
      display: flex;
      height: 100%;
      align-items: center;
      // logo
      .n-logo {
        width: 300px;
        // height: 100%;
        font-size: 18px;
        color: $colorZ;
        margin-right: 15px;
      }
      // 列表
      .n-list {
        display: flex;
        width: 600px;
        height: 100%;
        align-items: center;
        margin-left: 30px;
        a {
          color: #333;
          flex: 1;
          // background: pink;
          margin: 0 15px;
          height: 100%;
          font-size: 14px;
          line-height: 80px;
          text-align: center;
          color: $colorZ;
          font-weight: 400;
          transition: all 0.2s;
          &:hover {
            background-color: $colorZ;
            color: #fff;
          }
        }
      }
    }
    // 登录注册
    .n-login {
      .n-login2 {
        width: 80px;
        height: 35px;
        border: 1px dashed $colorZ;
        text-align: center;
        line-height: 35px;
        font-size: 14px;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
          background-color: $colorZ;
          color: #fff;
        }
      }
      span {
        color: #333;
        margin: 0 10px;
        cursor: pointer;
        transition: all 0.2s;
        font-size: 14px;
        &:hover {
          color: $colorZ;
        }
      }
    }
  }
}
</style>
